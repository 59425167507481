<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
  <div class="register">
  	<div class="box-left">
  		<div class="logo"><img :src="logo" style=""/></div>
  		<div class="title">
  			BEGIN YOUR <br/>CO-CREATION <br/>JOURNEY
  		</div>
  		<div class="desc">
  			Create an account through<br/>which you can submit your<br/>tenancy application
  		</div>
  	</div>
  	<div class="form-box">
  		<div class="block-nav">
  			<a href="javascript:;" @click="$router.push({path: '/'});">Back to Home</a>
  			<a href="javascript:;" @click="$router.push({path: '/zh-HK'});">繁</a>
  			<a href="javascript:;" @click="$router.push({path: '/zh-CN'});">简</a>
  		</div>
  		<h3 class="form-title">Forgot Password</h3>
  		<div class="form">
  			
          <a-alert
            v-if="isError"
            :message="errorMessage"
            type="error"
            banner
            closable
          />
            <a-form-item label="Email">
                <a-input
                  class="form-control"
                  v-decorator="['email', { rules: [
                  {type: 'email',message:'The input is not valid E-mail!'},
                  { required: true, message: 'Please input your E-mail!' }]
                   }]"
                />
              </a-form-item>
             
            
            <div class="form-group" style="margin-top:40px;">
                <button class="btn btn-info btn-block" type="submit">Confirm</button>
            </div>
            <div class="form-group">
            	<p class="m-notice">Don't have an account yet? <a href="javascript:;" @click="$router.push({path: '/register'});">Sign Up</a></p>
            </div>
  		</div>
  	</div>
  </div>
  <div v-if="isLoad">
    <a-spin />
  </div>
</a-form>
</template>
<script>
import global from '../global'
import jq from 'jquery'
export default {
    name: 'Findpassword',
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
           form: this.$form.createForm(this, { name: 'coordinated' }),
           isLoad:false,
           errorMessage:'',
           isError:false
        }
    },
    mounted() {
        this.$cookies.remove('_TOKEN_')
        this.$cookies.remove('_USER_')
    },
    methods: {
      handleSubmit(e){
          e.preventDefault();
          let that = this;
          that.isError = false;
          this.form.validateFields((err, values) => {
              if (!err) {
                that.isLoad = true;
                jq.post(global.api.domain+"forntend/sendResetPasswordEmail.do",values,function(response){
                    that.isLoad = false;
                    if(response.code == 0){
                      
                       that.$message.success(
                          'Successful!',
                          2,
                        ).then(()=>{
                           window.location.href = "/#/login"
                        });

                    }else{
                        if(response.msg != null){
                            that.isError = true;
                            that.errorMessage = response.msg;
                        }
                    }
                    console.log('Received values of form: ', response);
                })
                
            }
          });
        }
    }
}
</script>
<style>
	body{
		background: #F1F1F1;
	}
	#app{
		height:99.8%;
		background: #F1F1F1;
	}
	.main-box{
		height:100%;
		
	}
	.content{
			height:100%;
		}
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;

	}
}
</style>